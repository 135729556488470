.eligiblesection{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    background-image: url(../../Assets/Images/bubble\ img.jpg);
}

.eligiblediv{
    width: 70%;
}

.eligiblediv h3{
    text-align: center;
    margin-top: 20px;
}

.eligiblediv p{
    text-align: center;
    margin-top: 30px;
    font-size: 1.3rem;
}

.eligiblediv p span{
    color: var(--primary);
    font-weight: 600;
    font-size: 1.5rem;
}

.eligiblecarddiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eligiblecard{
    display: flex;
    gap: 20px;
    width: 66%;
    margin-top: 20px;
}

.eligiblecard1{
    justify-content: flex-end;
}

.eligiblecardinfodiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.eligiblecardinfodiv h4{
    font-weight: 600;
}

.eligiblecardinfodiv p{
    margin: 0;
    text-align: start;
}

@media screen and (max-width:1024px) {
    .eligiblediv{
        width: 85%;
    }

    .eligiblecard{
        width: 85%;
        gap: 10px;
    }
}

@media screen and (max-width:768px) {
    .eligiblediv{
        width: 95%;
    }

    .eligiblecard{
        width: 96%;
        gap: 10px;
    }
}