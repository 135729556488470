.maindivsticky1 {
    border-radius: 50%;
    width: fit-content;
    padding: 0.8rem;
    background-color: var(--primary);
    cursor: pointer;
}

.maindivstickytop {
    position: fixed;
    right: 2rem;
    bottom: 3rem;
    z-index: 20;
    width: fit-content;
}

.contactMenu{
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.36);
    width: 250px;
    height: 165px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
}

.callMenu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.callMenulinkdiv a{
    display: flex;
    gap: 10px;
    width: 100%;
    text-decoration: none;
    color: black;
}

.callMenuIcon{
    height: 30px;
    width: 30px;
    color: var(--primary);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.callMenu p{
    font-weight: 600;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width:768px) {
    .maindivstickytop {
        bottom: 6rem;
        right: 1rem;
    }

    .contactMenu{
        width: 93vw;
        height: 25vh;
        right: 0rem;
        bottom: 4rem;
        padding: 20px;
    }
}