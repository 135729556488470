.bottominfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.bottominfo h4{
    margin-top: 10px;
}


.bottominfodiv{
    background-color: var(--primary);
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
}

.bottominfodiv p{
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}