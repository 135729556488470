.mobileviewheaderdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 999;
}

.mobileviewheaderrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.newheaaderrow1column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 5px;
    color: white;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 5px;
}

.newheaaderrow1column1 p{
    margin-bottom: 0;
}

@media screen and (max-width:600px) {
    .mobileviewheaderdiv {
        display: flex;
        position: sticky;
    }
}