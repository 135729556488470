.popularcoursesdiv{
    margin-bottom: 50px;
}

.popularcourse-item{
    width: calc(25% - 26px);
    padding: 20px;
    /* box-shadow: 0 0 5px rgba(15, 15, 15, 0.26); */
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.popularcourse-item:hover{
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.36);
}
.popularcoursesheading{
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #3c4852;
    margin-bottom: 2rem;
}

@media screen and (max-width:768px) {
    .popularcoursesdiv{
        margin: 30px 0 !important;
    }
    .popularcourse-item{
        width: 100%;
        display: flex;
        box-shadow: none;
        padding:0 ;
        border-bottom: none;
        border-radius:  8px 8px 0 0;
        margin: 0 !important;
    }
    .popularcoursesheading{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}