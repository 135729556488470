.studentcarddiv{
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px 0;
    /* border: 2px solid rgba(100, 100, 111, 0.2); */
    width: 32%;
    background-color: white;
}


.studentcardtop p{
    margin-bottom: 0;
}

.studentdetail{
    display: flex;
    gap: 10px;
    /* width: 100%; */
    margin: 20px;
    margin-bottom: 0;
}

.studentimgdiv img{
    height: 80px;
    width: 100%;
}

.studentinfodiv p{
    margin-bottom: 0;
}

.placecompanyimgdiv{
    display: flex;
    justify-content: center;
}
.placecompanyimgdiv img{
    height: 50px;
    width: 50%;
}

.studentdetailbottomparadiv{
    margin: 20px;
    text-align: center;
    margin-bottom: 0;
}

.studentdetailbottomparadiv p{
    margin-bottom: 0;
}

@media screen and (max-width:768px) {
    .studentdetail{
        margin: 10px;
    }

    .studentimgdiv img{
        height: 70px;
    }
}


@media screen and (max-width:600px) {
    .studentcarddiv{
        width: 100%;
    }
}
