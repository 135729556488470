:root {
    --primary: #06BBCC;
    --light: #F0FBFC;
    --dark: #181d38;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a{
    text-decoration: none !important;
}

ul{
    list-style: none;
    display: flex;
}

.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: .5s;
}

.primarycolor{
    color: var(--primary);
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
    background-color: var(--primary);
    border: none;
}

.bgprimary{
    background-color: var(--primary);
}

.text-primary{
    color: var(--primary);
}

.section-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
}

.section-title::before {
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: 2px;
    top: 4px;
    left: -40px;
    background: var(--primary);
    z-index: -1;
}

.brochure-section-title{
    font-weight: 700;
}

.brochure-section-title::before{
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: 2px;
    top: 30px;
    left: -40px;
    background: var(--primary);
    z-index: -1;
}

.section-title::after {
    position: absolute;
    content: "";
    width: calc(100% + 120px);
    height: 2px;
    bottom: 5px;
    left: -60px;
    background: var(--primary);
    z-index: -1;
}

.section-title.text-start::before {
    width: calc(100% + 40px);
    left: 0;
}

.section-title.text-start::after {
    width: calc(100% + 60px);
    left: 0;
}
