.curriculumsection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2f80ec;
    padding: 50px 0;
}

.curriculumdiv{
    width: 70%;
}

.curriculumdiv h3{
    margin-top: 30px;
    font-size: 1.5rem;
    text-align: center;
    color: white;
}

.curriculumdesc{
    background-color: white;
    border-radius: 10px;
    width: 70%;
    padding: 10px 10px 10px 30px;
    margin-top: 30px;
    border: 2px solid #eee;
}

.curriculumdesc p{
    margin-bottom: 0;
}

@media screen and (max-width:1024px) {
    .curriculumdiv{
        width: 85%;
    }
    .curriculumdesc{
        width: 85%;
    }
}


@media screen and (max-width:768px) {
    .curriculumdiv{
        width: 95%;
    }
    .curriculumdesc{
        width: 95%;
    }
}