.teamsection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    background-color: #2f80ec;
}

.teamdiv{
    width: 70%;
}

.teamdiv h1{
    text-align: center;
}

.teamdiv p{
    text-align: center;
}

.teamheading{
    color: white;
    font-weight: 700;
    font-size: 4rem;
}

.teamdiv p{
    line-height: 1.7;
    font-size: 1.5rem;
    margin-top: 30px;
    color: white;
}

.teamcard:nth-child(2){
    margin-top: 100px;
}

.teamcard:nth-child(4){
    margin-top: 100px;
}

.teamimage{
    height: 250px;
    width: 100%;
    border-radius: 10px 10px 0 0 ;
}

.teamrow{
    margin: 50px 0;
}

@media screen and (max-width:768px) {
    .teamdiv{
        width: 95%;
    }
}


@media screen and (max-width:600px) {
    .teamsection{
        margin: 0 ;
    }
  
    .teamheading{
        font-size: 3rem;
    }

    .teamdiv p {
        line-height: 1.2;
        font-size: 1rem;
        text-align: justify !important;
    }
    .teamimage{
        height: 150px;
    }
}
