.highlightsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    background-image: url(../../Assets/Images/bubble\ img.jpg);
}

.highlightdiv {
    width: 70%;
}

.highlightdesc {
    display: flex;
    width: 100%;
    margin-top: 30px;
    gap: 20px;
}

.highlightimgdiv {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.highlightimgdiv img {
    width: 30%;
    height: 30px;
}

.highlightparadiv {
    width: 90%;
}

.highlightbottom{
    margin-top: 50px;
    width: 70%;
    column-gap: 30px;
}

.highlightbtmdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    height: 180px;
    gap: 20px;
}

.highlightbtmdiv img{
    height: 50px;
    width: 50px;
}

.highlightbtmdiv p{
    text-align: center;
    margin: 0;
}

@media screen and (max-width:1024px) {
    .highlightdiv {
        width: 95%;
    }

    .highlightbottom{
        width: 100%;
        gap: 10px;
    }
}

@media screen and (max-width:768px) {

    .highlightbtmdiv{
        height: 165px;
        gap: 10px;
        padding: 5px;
    }

    .highlightbtmdiv p{
        font-size: 0.9rem;
    }
}

@media screen and (max-width:600px) {
   

    .highlightimgdiv img {
        width: 80%;
    }

    .highlightbtmdiv{
        height: 120px;
        gap: 10px;
        padding: 5px;
    }

    .highlightbtmdiv img{
        height: 30px;
        width: 30px;
    }

    .highlightbtmdiv p{
        font-size: 0.55rem;
    }
}