.journeydiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.journeyimgdiv{
    width: 80%;
    display: flex;
    justify-content: center;
}

.journeyimgdiv img{
    width: 95%;
}