.featuredcarddiv {
    padding: 10px 20px;
}

.featuredcardinnerdiv {
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: white;
}

.featuredcardinnerdiv img {
    height: 70px;
    width: 70px;
}

.featuredcardinnerdiv p {
    margin-bottom: 0;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width:600px) {
    .featuredcarddiv {
        padding: 8px;
    }

    .featuredcardinnerdiv {
        height: 140px;
        padding: 10px;
    }

    .featuredcardinnerdiv p {
        font-size: 0.8rem;
    }

    .featuredcardinnerdiv img {
        height: 50px;
        width: 50px;
    }
}