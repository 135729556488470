.studentdetailsection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.studentcategory{
    display: flex;
    justify-content: flex-start;
    width: 80%;
    margin-top: 30px;
}

.studentcategorypara{
    text-align: center;
    background-color: var(--primary);
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.studentcategorypara p{
    margin: 0;
}

.studentdetailrow{
    margin: 10px 0;
    width: 80%;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width:1024px) {
    .studentdetailrow{
        width: 95%;
    }

    .studentcategory{
        width: 95%;
    }
    
}