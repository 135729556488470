.pricing{
    background-color: #2f80ec;
}

.priceimagediv{
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.priceimage{
    height: 100px;
    width: 100px;
}

@media screen and (max-width:600px) {
    .priceimage{
        height: 60px;
        width: 60px;
    }
}