.timesection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 50px 0 ; */
    padding: 50px 0;
    background-image: url(../../Assets/Images/bubble\ img.jpg);
}

.timediv{
    width: 70%;
}

.quotediv{
    width: 70%;
    margin-top: 30px;
}

.quotediv p{
    text-align: start;
    font-size: 1.7rem;
    width: 95%;
    font-style: italic;
}

.quotebtndiv{
    display: flex;
    justify-content: flex-end;
}

.quotebtn{
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-color: transparent;
    padding: 8px;
    border-radius: 8px;
    color: var(--primary);
    font-weight: 600;
}

.timeparadiv{
    width: 70%;
    margin-top: 30px;
    border: 7px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 30px;
    background-color: white;
}
.timeparadiv p{
    text-align: center;
    line-height: 2;
    color: black;
    font-size: 20px;
}

@media screen and (max-width:1024px) {
    .timediv,.quotediv,.timeparadiv{
        width: 85%;
    }
}

@media screen and (max-width:768px) {
    .timediv,.quotediv,.timeparadiv{
        width: 95%;
    }
}

@media screen and (max-width:600px) {
   

    .quotediv p{
        font-size: 1rem;
    }
    
    .timeparadiv{
        padding: 15px;
    }
    .timeparadiv p{
        font-size: 1rem;
    }
}

