.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
}

.page-header {
    background: linear-gradient(rgba(24, 29, 56, .7), rgba(24, 29, 56, .7)), url(../../Assets/Images/carousel-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 999;
}
