
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.navbarcontainer {
  width: 100%;
  margin-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background: white;
  position: relative;
}

.logo img{
    width: 200px;
    height: 100%;
}

.logoimage{
    width: 40px;
}

.menu-icon {
  display: none;
}

.nav-elements  {
    display: flex;
    height: 100%;
    align-items: center;
    column-gap: 10px;
    justify-content: flex-end;
}

.nav-elements ul {
  display: flex;
  /* justify-content: space-between; */
  list-style-type: none;
  margin-bottom: 0;
  height: 100%;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-elements ul li a {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: var(--primary);
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
}

.nav-elements div{
    display: flex;
    align-items: center;
}

.downloadbtnli{
  display: none !important;
}




@media (max-width: 768px) {
  .navbarcontainer{
    margin-left: 10px;
    width: 90%;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {

  .menu-icon {
    display: block;
    cursor: pointer;
    color: black;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background: white;
    width: 0px;
    height: 55vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 999;
    justify-content: flex-start;
  }

  .nav-elements.active {
    width: 100%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding-left: 10px !important;
  }

  .nav-elements ul li {
    margin-right: unset;
    justify-content: flex-start;
    margin-top: 22px;
    padding-left: 20px;
  }

  .nav-elements ul li a{
    color: var(--primary);
  }

  .downloadbtnli{
    display: block !important;
    margin-right: 30px !important;
    /* width: 100% !important; */
  }

  .downloadbtn{
    padding: 10px;
    color: white !important;
    background-color: var(--primary);
    border: none;
    border-radius: 8px; 
  }

  .joinnowbtn{
    padding: 10px 18px;
    color: white !important;
    background-color: var(--primary);
    border: none;
    border-radius: 8px; 
  }
}
