.courseslink{
    text-decoration: none !important;
    padding: 20px !important;
}

.courseimage{
    width: 270px;
    height: 194px;
    transition: .5s;
    /* object-fit: cover; */
}

.coursename{
    margin: 8px 0;
    color: #3c4852;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    min-height: 48px;
}

.courseimage:hover{
    transform: scale(1.04);
}


.course-card-body{
    min-height: 250px;
}

.coursebtndiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.coursepricediv{
    width: 100%;
}

.coursepricediv h5{
    margin: 8px 0;
    color: #3c4852;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

.coursepricediv .span1{
    color: black;
    text-decoration: line-through;
    margin-right: 5px;
}

.coursepricediv .span2{
    color:  rgb(252, 124, 73);
}

.coursebtn{
    display: flex;
    align-items: center;
    width: 50%;
}

.coursebtn a{
    margin-bottom: 0 !important;
}

.card-body-btns-div{
    display: flex;
    margin: 10px 0;
    gap: 10px;
    overflow: hidden;
    width: 100%;
}

.card-body-btns-div > button{
    padding: 0;
    border: none;
    color: rgb(60, 72, 82);
    background-color: rgb(240, 244, 248);
    font-size: 0.7rem;
    font-weight: 600;
    width: max-content;
}

.card-body-btn{
    padding: 4px 8px !important;
}

.course-card-btn-div{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.course-item1{
    padding: 20px;
    /* box-shadow: 0 0 5px rgba(15, 15, 15, 0.26); */
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.course-item1:hover{
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.36);
}


.card-img-top{
    width: 100%;
    height: 250px;
}
.coursesrow a{
    margin: 0 20px;
}

.discountdiv{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.popularcoursesslider{
    position: relative;
}

.popularcoursesslider .slick-prev{
    position: absolute;
    bottom: -10%;
    left: 44%;
    padding: 5px 10px;
    border: none;
}

.popularcoursesslider .slick-prev::before{
    content: "<";
}

.popularcoursesslider .slick-next{
    position: absolute;
    right: 44%;
    bottom: -10%;
    padding: 5px 10px;
    border: none;
}

.popularcoursesslider .slick-next::after{
    content: ">";
}

.coursebottomdiv{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0 0 8px 8px;
    display: none;
    border-top: none;
    
}

.courseimagediv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.discountimage{
    height: 20px;
    width: 20px;
}

.coursename{
    font-size: 1.2rem;
}

.discount{
    font-size: 1rem;
}

@media screen and (max-width:768px){
    .courseslink{
        border-radius: 8px;
        padding: 8px !important;
        padding: 0 !important; 
        /* border: 1px solid rgba(0, 0, 0, 0.2); */
    }
    .course-item1{
        display: flex;
        margin: 0 !important;
        box-shadow: none;
        padding: 0;
        border-radius: 8px 8px 0 0;
        border-bottom: none;
    }

    .course-item1:hover{
        box-shadow:none;
    }

    .courseimage{
        width: 100% !important;
        height: 100px !important;
    }

    .courseimagediv{
        width: 42%;
        align-items: flex-start;
        padding-top: 20px;
        padding-right: 0 !important;
        padding-left: 5px;
    }

    .courseimage{
        width: 100% !important;
        height: 120px !important;
        /* object-fit: cover; */
    }
    .discountimage{
        height: 15px;
        width: 15px;
    }
    .coursename{
        font-size: 1rem;
        margin-bottom: 10px !important;
    }

    .discountdiv{
        display: none;
    }

    .discount{
        font-size: 0.8rem;
    }

    .coursepricediv h5{
        display: inline;
        margin-right: 5px;
    }

    .coursebtn{
        display: none;
    }

    .coursescontentdiv{
        padding: 10px 0 10px 10px;
        width: 65%;
    }

    .coursebottomdiv{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        margin: 0 !important;
        /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
    }

    .card-body-btns-div{
        margin: 8px 0;
        gap: 5px;
    }

    .card-body-btns-div > button {
        font-size: 0.5rem;
        color: black;
    }

    .coursepricediv .span1{
        font-size: 0.8rem;
    }
    
    .coursepricediv .span2{
        font-size: 0.8rem;
    }
}
