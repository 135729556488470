
.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
}

.pricing hr {
    margin: 1.5rem 0;
}

.pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
}

.pricing .card-price {
    font-size: 1rem;
    margin: 0;
}

.pricing .card-price1 {
    font-size: 2rem;
    color: var(--primary);
    margin: 0;
    margin-top: 10px;
}

.pricing .card-price .period {
    font-size: 0.8rem;
}

.pricing ul {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 0 10px;
    margin-top: 20px;
}

.pricing ul li {
    margin-bottom: 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.pricing .text-muted {
    opacity: 0.7;
}

.pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .pricing .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }

    .pricing .card:hover .btn {
        opacity: 1;
    }
}

@media screen and (max-width:600px) {
    .pricing .card-price {
        font-size: 0.8rem;
    }

    .pricing ul {
        padding: 0;
    }

    .pricing ul li p{
        text-align: center;
        font-size: 1rem;
    }

    .pricing .card-price1 {
        font-size: 1rem;
    }

    .card-body{
        padding: 10px !important;
    }
}