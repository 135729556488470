.companies{
    padding: 50px 0;
    /* background-color: white; */
}

.companiesimagediv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.companiesimagediv img{
    height: 80px;
    width: 75%;
}

@media screen and (max-width:600px) {

    .companies img {
        width: 100%;
        height: 40px;
    }
}